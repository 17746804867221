export enum EAppConstantsKeys {
  APP_VERSION = 'APP_VERSION',
  AXON_URL = 'AXON_URL',
  BASE_DOMAIN = 'BASE_DOMAIN',
  BASIC_AUTH_CEREBRO = 'BASIC_AUTH_CEREBRO',
  BASIC_AUTH_PLEXUS = 'BASIC_AUTH_PLEXUS',
  SENTRY_DSN = 'SENTRY_DSN',
  SENTRY_ENVIRONMENT = 'SENTRY_ENVIRONMENT',
  SENTRY_TRACES_SAMPLE_RATE = 'SENTRY_TRACES_SAMPLE_RATE',
  IFAP_URL = 'IFAP_URL',
  MEDBASE_URL = 'MEDBASE_URL',
  CEREBRO_URL = 'CEREBRO_URL',
  CEREBRO_REST_URL = 'CEREBRO_REST_URL',
  PLEXUS_URL = 'PLEXUS_URL',
  PLEXUS_WS_URL = 'PLEXUS_WS_URL',
  TELEMETRY_URL = 'TELEMETRY_URL',
  MEDBASE_AUTH = 'MEDBASE_AUTH',
  INCLUDE_GOOGLE_TAG_MANAGER = 'INCLUDE_GOOGLE_TAG_MANAGER',
  GOOGLE_ANALYTICS_ID = 'GOOGLE_ANALYTICS_ID',
  TEST_EMAIL = 'TEST_EMAIL',
  TEST_PASSWORD = 'TEST_PASSWORD',
  MOBILE_APP_LINK_IOS_AT = 'MOBILE_APP_LINK_IOS_AT',
  MOBILE_APP_LINK_IOS_DE = 'MOBILE_APP_LINK_IOS_DE',
  MOBILE_APP_LINK_ANDROID_AT = 'MOBILE_APP_LINK_ANDROID_AT',
  MOBILE_APP_LINK_ANDROID_DE = 'MOBILE_APP_LINK_ANDROID_DE',
}

export type TAppConstants = Record<EAppConstantsKeys, any>
