import { TAtcTree } from '_types'

class atc {
  private code: string
  private matches: Array<string> | null
  private pathsArr: Array<string> | null

  constructor(code: string = '') {
    this.code = code
    this.matches = null
    this.pathsArr = null
  }

  public static from(code: string): atc {
    return new atc(code)
  }

  public parse(): atc {
    const rx = /([a-zA-Z])(?:(\d\d)(?:([a-zA-Z])(?:([a-zA-Z])(\d\d)?)?)?)?/
    let tmpMatches = this.code.match(rx)

    this.matches = tmpMatches ? tmpMatches.filter(Boolean).slice(0, 6) : null

    return this
  }

  public paths(): atc {
    this.pathsArr =
      this.matches &&
      this.matches.slice(1).map((match, index, matchesArr) => {
        return match
          ? matchesArr
              .slice(0, index + 1) // Each iteration we want to concat more elements
              .filter(Boolean) // Filter out undefined and null values
              .map((m) => m.toUpperCase()) // Uppercase values
              .join('') // Join into string
          : ''
      })

    return this
  }

  public get(): Array<string> | null {
    return this.pathsArr ? this.pathsArr : this.matches
  }
}

const generateTree = (data: any): TAtcTree => {
  return {
    a1: data?.a1
      ? { code: data.a1.code!, description: data.a1.description! }
      : null,
    a2: data?.a2
      ? { code: data.a2.code!, description: data.a2.description! }
      : null,
    a3: data?.a3
      ? { code: data.a3.code!, description: data.a3.description! }
      : null,
    a4: data?.a4
      ? { code: data.a4.code!, description: data.a4.description! }
      : null,
    a5: data?.a5
      ? { code: data.a5.code!, description: data.a5.description! }
      : null,
  }
}

export const atcHelper = { atc, generateTree }
