import { useState } from 'react'
import { connect } from 'react-redux'
import { Form, Button } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { Formik } from 'formik'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { routeConstants } from '_constants'
import OnboardingDialog from '_components/Common/OnboardingDialog'
import { passwordRecoveryValidationSchema } from '_helpers/onboarding.helper'
import { usePasswordResetEmailMutation } from '_generated/plexus.graphql'
import { gql } from '_helpers/gql.helper'
import * as Sentry from '@sentry/react'
import {
  AlertMessage,
  EAlertMessageType,
} from '_components/Common/AlertMessage'
import { getCombinedPlexusErrorMessage } from '_helpers/plexus.helper'
import { TRootState } from '_helpers'

export enum ETestIds {
  emailError = 'password-recovery-email-error',
}

export enum EComponentState {
  default,
  loading,
  success,
  error,
}

type TProps = {
  initialEmail: string
  initialComponentState?: EComponentState
}

const mapStateToProps = (state: TRootState) => {
  const { authentication, temp } = state
  const userEmail = authentication.user?.email
  const tempEmail = temp.email ?? ''
  return {
    initialEmail: userEmail ?? tempEmail,
  }
}

function ConnectedPasswordRecoveryPage({
  initialEmail,
  initialComponentState = EComponentState.default,
}: TProps) {
  const navigate = useNavigate()
  const [internalState, setInternalState] = useState(initialComponentState)
  const [email, setEmail] = useState(initialEmail)
  const [errorMessage, setErrorMessage] = useState('')

  const updateComponentState = (newState: EComponentState) => {
    if (internalState !== newState) {
      setInternalState(newState)
    }
  }

  const [passwordResetEmailMutation] = usePasswordResetEmailMutation({
    client: gql.plexusClient,
    onError: (error) => {
      let errorMessage = getCombinedPlexusErrorMessage(error)
      setErrorMessage(errorMessage)
      updateComponentState(EComponentState.error)
    },
    onCompleted: (data) => {
      if (data?.userManagementPasswordResetEmail) {
        updateComponentState(EComponentState.success)
      } else {
        updateComponentState(EComponentState.error)
      }
    },
  })

  return (
    <OnboardingDialog
      title="Passwort vergessen"
      paragraph={
        internalState === EComponentState.default ||
        internalState === EComponentState.loading
          ? 'Du hast dein Passwort vergessen? Wir schicken ein E-Mail an deine Adresse zur Wiederherstellung.'
          : internalState === EComponentState.success
            ? 'Öffne das E-Mail, das wir dir soeben gesendet haben, um ein neues Passwort zu vergeben.'
            : ''
      }
      showLoginLink={internalState === EComponentState.default}
    >
      {(internalState === EComponentState.default ||
        internalState === EComponentState.loading) && (
        <Formik
          initialValues={{
            email: email,
          }}
          validationSchema={passwordRecoveryValidationSchema}
          onSubmit={(values) => {
            setEmail(values.email)
            updateComponentState(EComponentState.loading)
            passwordResetEmailMutation({
              variables: {
                emailAddress: values.email,
              },
            }).catch((error) => Sentry.captureException(error)) // To avoid unhandled rejection
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
          }) => (
            <Form
              // @ts-ignore
              onSubmit={handleSubmit}
              className="mt-4"
              aria-label="Passwort vergessen"
            >
              <Form.Group controlId="email" className="mx-sm-3">
                <Form.Label visuallyHidden={true}>E-Mail Adresse</Form.Label>
                <Form.Control
                  className="onboarding"
                  isInvalid={!!errors.email && !!touched.email}
                  disabled={internalState === EComponentState.loading}
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder="E-Mail Adresse"
                  type="email"
                  value={values.email}
                />
                <Form.Control.Feedback
                  data-testid={ETestIds.emailError}
                  type="invalid"
                  className="text-end"
                >
                  {errors.email}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-4 mt-5 mx-sm-3">
                <div className="d-grid">
                  <Button
                    aria-label="Absenden"
                    disabled={internalState === EComponentState.loading}
                    className="btn btn-green"
                    type="submit"
                    variant="primary"
                  >
                    {internalState === EComponentState.loading ? (
                      <FontAwesomeIcon icon={faSpinner} spin />
                    ) : (
                      'Absenden'
                    )}
                  </Button>
                </div>
              </Form.Group>
            </Form>
          )}
        </Formik>
      )}

      {internalState === EComponentState.error && (
        <AlertMessage
          type={EAlertMessageType.error}
          message={errorMessage}
          text="Hast du dich vielleicht vertippt? Melde dich beim Support, falls du Unterstützung benötigst."
          buttonText="Support kontaktieren"
          onBackButtonClick={() => {
            updateComponentState(EComponentState.default)
          }}
        />
      )}

      {internalState === EComponentState.success && (
        <AlertMessage
          type={EAlertMessageType.success}
          message="E-Mail versendet"
          buttonText="Zurück"
          onClick={() => {
            navigate(routeConstants.LOGIN_PAGE)
          }}
        />
      )}
    </OnboardingDialog>
  )
}

const PasswordRecoveryPage = connect(mapStateToProps)(
  ConnectedPasswordRecoveryPage,
)

export { PasswordRecoveryPage, ConnectedPasswordRecoveryPage }
