import { takeEvery, call, put, all } from 'redux-saga/effects'
import { Action } from '@reduxjs/toolkit'
import * as Sentry from '@sentry/react'
import { userService, Telemetry } from '_services'
import { TUserManagementUserAndSession } from '_types'
import { getPlexusErrorMessages } from '_helpers/plexus.helper'
import { errorAlert, flash, EAlertActionTypeKeys } from '_slices/alert.slice'
import {
  loginRequest,
  loginRequested,
  loginSuccess,
  loginFailure,
  logoutRequest,
  logoutRequested,
  fetchUserRequest,
  registerRequest,
  registerRequested,
  registerFailure,
  registerSuccess,
  fetchUserSuccess,
  profileUpdateRequested,
  profileUpdateRequest,
  profileUpdateSuccess,
  profileUpdateFailure,
  invoiceDataUpdateRequested,
  invoiceDataUpdateSuccess,
  invoiceDataUpdateFailure,
  tokenLoginRequest,
  invoiceDataUpdateRequest,
} from '_slices/authentication.slice'
import { userHelper } from '_helpers'
import { TUserManagementUser } from '_generated/plexus.graphql'

const activateExternalServices = process.env.NODE_ENV !== 'test'

// -- HANDLER ERRORS

function* handleError(error: any) {
  let errorMessages = getPlexusErrorMessages(error)
  let combinedErrorMessage = errorMessages.join('\n')
  yield put(errorAlert(combinedErrorMessage))

  // just in case we can't get any error messages from this error, we better log it
  if (errorMessages.length === 0) {
    Sentry.captureException(error)
  }
}

// -- LOGIN

export function* loginRequestHandler(data: Action) {
  try {
    if (loginRequest.match(data)) {
      yield put(loginRequested())

      // 1. get the user tokens
      const userAndSession: TUserManagementUserAndSession = yield call(
        userService.login,
        data.payload.email,
        data.payload.password,
      )

      // 2. get the user data
      const { user, session } = userAndSession
      yield put(loginSuccess({ ...session, user }))

      if (activateExternalServices) {
        Telemetry.setUserId(user.uuid!)
        Telemetry.login()

        Sentry.setUser({
          id: user.uuid!,
          email: user.email!,
        })
      }
    }
  } catch (error) {
    yield put(loginFailure())
    yield* handleError(error)
  }
}

function* watchLoginRequest() {
  yield takeEvery(loginRequest.type, loginRequestHandler)
}

// -- TOKEN LOGIN

export function* tokenLoginRequestHandler(data: Action) {
  try {
    if (tokenLoginRequest.match(data)) {
      yield put(loginRequested())

      // Login with token
      const userAndSession: TUserManagementUserAndSession = yield call(
        userService.tokenLogin,
        data.payload,
      )

      const { user, session } = userAndSession
      yield put(loginSuccess({ ...session, user }))

      if (activateExternalServices) {
        Telemetry.setUserId(user.uuid!)
        Telemetry.login()

        Sentry.setUser({
          id: user.uuid!,
          email: user.email!,
        })
      }
    }
  } catch (error) {
    yield put(loginFailure())
    yield* handleError(error)
  }
}

function* watchTokenLoginRequest() {
  yield takeEvery(tokenLoginRequest.type, tokenLoginRequestHandler)
}

// -- LOGOUT

export function* logoutRequestHandler() {
  yield call(userService.logout)
  yield put(logoutRequested())

  if (activateExternalServices) {
    Telemetry.stopTimer()
    // If the user logs out and does not close the window or tab
    // before logging in again, the session would stay the same.
    // Therefore reset the session here.
    Telemetry.resetSession()
  }
}

function* watchLogoutRequest() {
  yield takeEvery(logoutRequest.type, logoutRequestHandler)
}

// -- USER DATA
export function* fetchUserRequestHandler(data: Action) {
  try {
    if (fetchUserRequest.match(data)) {
      // Get the user
      const user: TUserManagementUser = yield call(
        userService.fetchUser,
        data.payload,
      )

      // Put the user data into redux store
      yield put(fetchUserSuccess(user))

      if (activateExternalServices) {
        Telemetry.setUserId(user.uuid!)
        Telemetry.resetSession()

        Sentry.setUser({
          id: user.uuid!,
          email: user.email!,
        })
      }
    }
  } catch (error) {
    yield put(loginFailure())
    yield* handleError(error)
  }
}

function* watchFetchUserRequest() {
  yield takeEvery(fetchUserRequest.type, fetchUserRequestHandler)
}

// -- REGISTER

export function* registerRequestHandler(data: Action) {
  yield put(registerRequested())

  try {
    if (registerRequest.match(data)) {
      // Register the user and get the user tokens
      const userAndSession: TUserManagementUserAndSession = yield call(
        userService.register,
        data.payload.email,
        data.payload.password,
        data.payload.occupation,
      )

      // Get the user data
      const { user, session } = userAndSession
      yield put(registerSuccess({ ...session, user }))

      if (activateExternalServices) {
        Telemetry.setUserId(user.uuid!)

        Sentry.setUser({
          id: user.uuid!,
          email: user.email!,
        })
      }
    }
  } catch (error) {
    yield put(registerFailure())
    yield* handleError(error)
  }
}

function* watchRegisterRequest() {
  yield takeEvery(registerRequest.type, registerRequestHandler)
}

// -- USER PROFILE

export function* profileUpdateRequestHandler(data: Action) {
  yield put(profileUpdateRequested())

  try {
    if (profileUpdateRequest.match(data)) {
      const newProfile = data.payload.newProfile
      const oldProfile = data.payload.oldProfile

      // Update the user profile
      const user: TUserManagementUser = yield call(
        userService.profileUpdate,
        newProfile,
      )

      // Update the user data in the state
      yield put(profileUpdateSuccess(user))
      yield put(
        flash({
          message: 'Deine Daten wurden erfolgreich geändert.',
          type: EAlertActionTypeKeys.Success,
        }),
      )

      if (activateExternalServices) {
        Telemetry.setUserId(user.uuid!)
        // Send event when webMedCountry changed
        if (
          userHelper.isProfileDirty(oldProfile, newProfile, 'webMedCountry') &&
          newProfile.webMedCountry
        ) {
          Telemetry.countrySelected(newProfile.webMedCountry)
        }

        Sentry.setUser({
          id: user.uuid!,
          email: user.email!,
        })
      }
    }
  } catch (error) {
    yield put(profileUpdateFailure())
    yield* handleError(error)
  }
}

function* watchProfileUpdateRequest() {
  yield takeEvery(profileUpdateRequest.type, profileUpdateRequestHandler)
}

export function* invoiceDataUpdateRequestHandler(data: Action) {
  yield put(invoiceDataUpdateRequested())

  try {
    if (invoiceDataUpdateRequest.match(data)) {
      const newInvoiceData = data.payload

      const user: TUserManagementUser = yield call(
        userService.invoiceDataUpdate,
        newInvoiceData,
      )

      yield put(invoiceDataUpdateSuccess(user))
      yield put(
        flash({
          message: 'Deine Daten wurden erfolgreich geändert.',
          type: EAlertActionTypeKeys.Success,
        }),
      )
    }
  } catch (error) {
    yield put(invoiceDataUpdateFailure())
    yield* handleError(error)
  }
}

function* watchInvoiceDataUpdateRequest() {
  yield takeEvery(
    invoiceDataUpdateRequest.type,
    invoiceDataUpdateRequestHandler,
  )
}

// -- INIT

export default function* userSaga() {
  yield all([
    watchLoginRequest(),
    watchLogoutRequest(),
    watchRegisterRequest(),
    watchProfileUpdateRequest(),
    watchInvoiceDataUpdateRequest(),
    watchFetchUserRequest(),
    watchTokenLoginRequest(),
  ])
}
