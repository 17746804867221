import { useEffect } from 'react'
import { shallowEqual } from 'react-redux'
import toast from 'react-hot-toast'
import { Button, Container, Row, Col, Form } from 'react-bootstrap'
import { useFormik, Formik, Field, Form as FormikForm } from 'formik'
import Select from 'react-select'
import * as yup from 'yup'
import { gql } from '_helpers'
import {
  TUserManagementOccupationEnum as EOccupation,
  TUserManagementCountryEnum as ECountry,
  TUserManagementWorkplaceEnum as EWorkplace,
  TUserManagementWebMedCountryEnum as EWebMedCountry,
  TUserManagementSpecialityEnum as ESpeciality,
  TUserManagementInterestEnum as EInterest,
  TUserManagementVerificationNumberTypeEnum as EVerifyNumberType,
  TUserManagementFieldOfStudyEnum as EFieldOfStudy,
  useUserManagementConfigQuery,
  usePasswordResetEmailMutation,
  useResetEmailMutation,
} from '_generated/plexus.graphql'
import {
  TPlexusUserManagementConfigFilteredItem as TConfigItem,
  getPlexusConfig,
} from '_helpers/plexus.helper'
import ApolloErrorMsg from '_components/Common/ApolloErrorMsg'
import { profileUpdateRequest } from '_slices/authentication.slice'
import { EAlertActionTypeKeys } from '_slices/alert.slice'
import InvoiceForm from '_components/Profile/InvoiceForm'
import DeleteUserForm from '_components/Profile/DeleteUserForm'
import { useAppDispatch, useAppSelector } from '_hooks/store'
import ProfilePicUploader from '_components/Profile/ProfilePicUploader'
import { isValidOeaekNumber } from '_helpers/verificationNumber.helper'

const validationSchema = yup.object({
  firstName: yup
    .string()
    .min(3, 'Der Vorname muss min 3 Zeichen lang sein.')
    .required('Bitte gib deinen Vornamen an.'),
  lastName: yup
    .string()
    .min(3, 'Der Nachname muss min 3 Zeichen lang sein.')
    .required('Bitte gib deinen Nachnamen an.'),
  title: yup.string(),
  suffix: yup.string(),
  occupation: yup
    .string()
    .notOneOf([EOccupation.Undefined], 'Bitte gib deinen Fachkreis an.'),
  verificationNumber: yup.string().when('occupation', {
    is: EOccupation.Doctor,
    then: (num) =>
      num.test({
        message: 'Bitte gib eine gültige ÖAK Nummer an.',
        test: (value) => {
          return value && value.length > 0 ? isValidOeaekNumber(value) : true
        },
      }),
  }),
  interests: yup.array().when('occupation', {
    is: EOccupation.Doctor,
    then: (interests) =>
      interests
        .of(yup.string())
        .max(8, 'Gib bitte maximal acht Interessensgebiete an.'),
  }),
  specialities: yup.array().when('occupation', {
    is: EOccupation.Doctor,
    then: (specialities) =>
      specialities
        .of(
          yup
            .string()
            .notOneOf(
              [ESpeciality.Undefined],
              'Bitte gib min eine Fachrichtung an.',
            ),
        )
        .min(1, 'Gib bitte min eine Fachrichtung an.')
        .max(3, 'Gib bitte maximal drei Fachrichtungen an.'),
    otherwise: (specialities) =>
      specialities.of(
        yup
          .string()
          .oneOf(
            [ESpeciality.Undefined],
            "Bitte gib Fachrichtung nur an, wenn du als Fachkreis 'Ärzt:in' ausgewählt hast.",
          ),
      ),
  }),
  fieldOfStudy: yup.string().when('occupation', {
    is: EOccupation.Student,
    then: (fieldsOfStudy) =>
      fieldsOfStudy.notOneOf(
        [EFieldOfStudy.Undefined],
        'Bitte gib deine Studienrichtung an.',
      ),
    otherwise: (fieldsOfStudy) =>
      fieldsOfStudy.oneOf(
        [ESpeciality.Undefined],
        "Bitte gib die Studienrichtung nur an, wenn du als Fachkreis 'Student:in' ausgewählt hast.",
      ),
  }),
  workplace: yup
    .string()
    .notOneOf([EWorkplace.Undefined], 'Bitte gib deine Arbeitsstätte an.'),
  country: yup
    .string()
    .notOneOf(
      [ECountry.Undefined],
      'Bitte wähle das Land deiner Arbeitstätte aus.',
    ),
  webMedCountry: yup
    .string()
    .notOneOf(
      [EWebMedCountry.Undefined],
      'Bitte wähle das Land der Arzneimitteldaten aus.',
    ),
  zip: yup
    .string()
    .when('country', {
      is: ECountry.At,
      then: (zip) => zip.length(4, 'Die PLZ muss 4 Zeichen lang sein.'),
    })
    .when('country', {
      is: ECountry.De,
      then: (zip) => zip.length(5, 'Die PLZ muss 5 Zeichen lang sein.'),
    })
    .required('Bitte gib die PLZ deiner Arbeitstätte an.'),
})

type TInitialValues = {
  firstName: string
  lastName: string
  title: string
  suffix: string
  occupation: EOccupation
  verificationNumber: string
  verificationNumberType: EVerifyNumberType
  specialities: Array<ESpeciality>
  interests: Array<EInterest>
  fieldOfStudy: EFieldOfStudy
  workplace: EWorkplace
  country: ECountry
  webMedCountry: EWebMedCountry
  zip: string
}

export function ProfilePage() {
  const dispatch = useAppDispatch()
  const isUserProfileUpdating = useAppSelector(
    (state) => state.authentication.isUserProfileUpdating,
  )
  const user = useAppSelector(
    (state) => state.authentication.user,
    shallowEqual,
  )

  useEffect(() => {
    document.title = 'Diagnosia Profil'
  }, [])

  const flashMessage = useAppSelector((state) => state.alert.message)
  const flashType = useAppSelector((state) => state.alert.type)

  const { loading, error, data } = useUserManagementConfigQuery({
    client: gql.plexusClient,
  })

  const [resetPassword, { loading: isSendingResetPasswordMutation }] =
    usePasswordResetEmailMutation({
      client: gql.plexusClient,
      onCompleted: (data) => {
        if (data.userManagementPasswordResetEmail) {
          toast.success(
            'Wir haben weitere Anweisungen an deine E-Mail Adresse geschickt.',
          )
        }
      },
    })

  const [resetEmail, { loading: isSendingResetEmailMutation }] =
    useResetEmailMutation({
      client: gql.plexusClient,
      onCompleted: (data) => {
        if (data.userManagementNewEmail) {
          toast.success(
            'Wir haben weitere Anweisungen an deine neue E-Mail Adresse geschickt.',
          )
        }
      },
    })

  const profile = user?.profile
  const config = data?.userManagementConfig
  const occupationOptions = getPlexusConfig<EOccupation>(
    config?.occupations ?? [],
  )
  const specialitiesOptions = getPlexusConfig<ESpeciality>(
    config?.specialities ?? [],
  )
  const interestsOptions = getPlexusConfig<EInterest>(config?.interests ?? [])
  const countryOptions = getPlexusConfig<ECountry>(config?.countries ?? [])
  const workplaceOptions = getPlexusConfig<EWorkplace>(config?.workplaces ?? [])
  const studyFieldsOptions = getPlexusConfig<EFieldOfStudy>(
    config?.fieldsOfStudy ?? [],
  )

  const initialValues: TInitialValues = {
    firstName: profile?.firstName ?? '',
    lastName: profile?.lastName ?? '',
    title: profile?.title ?? '',
    suffix: profile?.suffix ?? '',
    occupation: profile?.occupation ?? EOccupation.Undefined,
    verificationNumber: profile?.verificationNumber ?? '',
    verificationNumberType:
      profile?.verificationNumberType ?? EVerifyNumberType.Undefined,
    specialities: profile?.specialities?.filter(Boolean) ?? [
      ESpeciality.Undefined,
    ],
    fieldOfStudy:
      profile?.fieldsOfStudy?.filter(Boolean)[0] ?? EFieldOfStudy.Undefined,
    interests: profile?.interests?.filter(Boolean) ?? [EInterest.Undefined],
    workplace: profile?.workplace ?? EWorkplace.Undefined,
    country: profile?.country ?? ECountry.De,
    webMedCountry: profile?.webMedCountry ?? EWebMedCountry.At,
    zip: profile?.zip ?? '',
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    onSubmit: (values, { setSubmitting }) => {
      if (profile) {
        const newProfile = {
          ...profile,
          ...values,
          verificationNumberType:
            values.verificationNumber !== ''
              ? EVerifyNumberType.Oeaek
              : EVerifyNumberType.Undefined,
          fieldsOfStudy: [values.fieldOfStudy],
        }
        dispatch(profileUpdateRequest(newProfile, { ...profile }))
        setSubmitting(false)
      }
    },
    validationSchema,
  })

  function handleResetPassword() {
    if (user?.email) {
      resetPassword({ variables: { emailAddress: user?.email } })
    }
  }

  function validateEmail(value: string) {
    let error
    if (!value) {
      error = 'Bitte gib deine neue E-Mail Adresse an.'
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
      error = 'Bitte gib eine gültige E-Mail Adresse an.'
    }
    return error
  }

  if (error) {
    return <ApolloErrorMsg error={error} />
  }

  return (
    <>
      <div className="bb-1-light p-0">
        <Container fluid="xl">
          <Row>
            <Col className="py-4">
              <div className="text-2xl font-bold">
                {user?.profile?.firstName} {user?.profile?.lastName}
              </div>
              <div
                title="E-Mail Adresse"
                className="text-sm color-blue-grey-60"
              >
                {user?.email}
              </div>
              {profile?.verificationNumber ? (
                <div title="ÖÄK Nummer" className="text-sm color-blue-grey-60">
                  {profile?.verificationNumber}
                </div>
              ) : null}
            </Col>
          </Row>
        </Container>
      </div>
      <Container fluid="xl">
        <Row className="mb-5">
          <Col md={6}>
            <Form
              className="form-profile"
              onReset={formik.handleReset}
              onSubmit={formik.handleSubmit}
            >
              <div className="text-lg font-bold py-4">Angaben zu Person</div>
              <Form.Group className="mb-3" controlId="title">
                <Form.Label>Titel vorangestellt</Form.Label>
                <Form.Control
                  disabled={loading || isUserProfileUpdating}
                  isInvalid={!!formik.errors.title}
                  name="title"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.title}
                  type="text"
                  placeholder="Dr., Mag., DI,..."
                />
                <Form.Control.Feedback type="invalid" className="text-end">
                  {formik.errors.title}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3" controlId="firstName">
                <Form.Label>Vorname</Form.Label>
                <Form.Control
                  disabled={loading || isUserProfileUpdating}
                  isInvalid={!!formik.errors.firstName}
                  name="firstName"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.firstName}
                  type="text"
                  placeholder="Gib deinen Vornamen an"
                />
                <Form.Control.Feedback type="invalid" className="text-end">
                  {formik.errors.firstName}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3" controlId="lastName">
                <Form.Label>Nachname</Form.Label>
                <Form.Control
                  disabled={loading || isUserProfileUpdating}
                  isInvalid={!!formik.errors.lastName}
                  name="lastName"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.lastName}
                  type="text"
                  placeholder="Gib deinen Nachnamen an"
                />
                <Form.Control.Feedback type="invalid" className="text-end">
                  {formik.errors.lastName}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3" controlId="suffix">
                <Form.Label>Titel nachgestellt</Form.Label>
                <Form.Control
                  disabled={loading || isUserProfileUpdating}
                  isInvalid={!!formik.errors.suffix}
                  name="suffix"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.suffix}
                  type="text"
                  placeholder="BSc, BA, MSc,..."
                />
                <Form.Control.Feedback type="invalid" className="text-end">
                  {formik.errors.suffix}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3" controlId="occupation">
                <Form.Label>Fachkreis</Form.Label>
                <Select
                  className={
                    !!formik.errors.occupation && !!formik.touched.occupation
                      ? 'select-wrapper is-invalid'
                      : 'select-wrapper'
                  }
                  classNamePrefix="select"
                  name="occupation"
                  inputId="occupation-input"
                  isDisabled={isUserProfileUpdating}
                  isLoading={loading}
                  options={occupationOptions}
                  onBlur={() => formik.setFieldTouched('occupation', true)}
                  onChange={(value, actionMeta) => {
                    let v = value as TConfigItem<EOccupation>
                    if (actionMeta.name && v) {
                      formik.setFieldValue(actionMeta.name, v.value)

                      // If user choosed something else than "Doctor",
                      // no specialities are allowed.
                      if (v.value !== EOccupation.Doctor) {
                        formik.setFieldValue('specialities', [
                          ESpeciality.Undefined,
                        ])

                        formik.setFieldValue('verificationNumber', '')
                      }

                      if (v.value !== EOccupation.Student) {
                        formik.setFieldValue(
                          'fieldOfStudy',
                          EFieldOfStudy.Undefined,
                        )
                      }
                    }
                  }}
                  placeholder="Wähle deinen Fachkreis"
                  value={occupationOptions.find(
                    (o) => o.value === formik.values.occupation,
                  )}
                />
                <Form.Control.Feedback type="invalid" className="text-end">
                  {formik.errors.occupation}
                </Form.Control.Feedback>
              </Form.Group>
              {formik.values.occupation === EOccupation.Doctor && (
                <>
                  <Form.Group className="mb-3" controlId="verificationNumber">
                    <Form.Label>Verifizierungsnummer</Form.Label>
                    <Form.Control
                      disabled={loading || isUserProfileUpdating}
                      isInvalid={
                        !!formik.errors.verificationNumber &&
                        !!formik.touched.verificationNumber
                      }
                      name="verificationNumber"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.verificationNumber}
                      type="text"
                      placeholder="ÖAK Nummer"
                    />
                    <Form.Control.Feedback type="invalid" className="text-end">
                      {formik.errors.verificationNumber}
                    </Form.Control.Feedback>
                  </Form.Group>
                </>
              )}
              <Form.Group className="mb-3" controlId="specialities">
                <Form.Label>Fachrichtung</Form.Label>
                <Select
                  className={
                    !!formik.errors.specialities &&
                    !!formik.touched.specialities
                      ? 'select-wrapper is-invalid'
                      : 'select-wrapper'
                  }
                  classNamePrefix="select"
                  isDisabled={
                    loading ||
                    isUserProfileUpdating ||
                    formik.values.occupation !== EOccupation.Doctor
                  }
                  isLoading={loading}
                  isMulti={true}
                  isSearchable={true}
                  menuPlacement="bottom"
                  name="specialities"
                  options={specialitiesOptions}
                  onBlur={() => {
                    formik.setFieldTouched('specialities', true)
                  }}
                  onChange={(selectedValues) => {
                    if (!selectedValues) {
                      selectedValues = []
                    }

                    let items = selectedValues as TConfigItem<ESpeciality>[]
                    let values = items.map((o) => o.value)
                    if (values) {
                      formik.setFieldValue('specialities', values)
                    }
                  }}
                  placeholder="Fachrichtung suchen"
                  value={formik.values.specialities
                    .map((speciality) =>
                      specialitiesOptions.find((o) => o.value === speciality),
                    )
                    .filter(Boolean)}
                />
                <Form.Control.Feedback type="invalid" className="text-end">
                  {formik.errors.specialities}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3" controlId="interests">
                <Form.Label>Weitere Interessensgebiete</Form.Label>
                <Select
                  className={
                    !!formik.errors.interests && !!formik.touched.interests
                      ? 'select-wrapper is-invalid'
                      : 'select-wrapper'
                  }
                  classNamePrefix="select"
                  isDisabled={
                    isUserProfileUpdating ||
                    formik.values.occupation !== EOccupation.Doctor
                  }
                  isLoading={loading}
                  isMulti={true}
                  isSearchable={true}
                  menuPlacement="bottom"
                  name="interests"
                  options={interestsOptions}
                  onBlur={() => formik.setFieldTouched('interests', true)}
                  onChange={(selectedValues) => {
                    if (!selectedValues) {
                      selectedValues = []
                    }

                    let items = selectedValues as TConfigItem<EInterest>[]
                    let values = items.map((o) => o.value)
                    if (values) {
                      formik.setFieldValue('interests', values)
                    }
                  }}
                  placeholder="Interessensgebiete suchen"
                  value={formik.values.interests
                    .map((interest) =>
                      interestsOptions.find((o) => o.value === interest),
                    )
                    .filter(Boolean)}
                />
                <Form.Control.Feedback type="invalid" className="text-end">
                  {formik.errors.interests}
                </Form.Control.Feedback>
              </Form.Group>
              {formik.values.occupation === EOccupation.Student && (
                <>
                  <Form.Group className="mb-3" controlId="fieldOfStudy">
                    <Form.Label>Studienrichtung</Form.Label>
                    <Select
                      className={
                        !!formik.errors.fieldOfStudy &&
                        !!formik.touched.fieldOfStudy
                          ? 'select-wrapper is-invalid'
                          : 'select-wrapper'
                      }
                      classNamePrefix="select"
                      inputId="fieldOfStudy-input"
                      isDisabled={loading || isUserProfileUpdating}
                      isLoading={loading}
                      name="fieldOfStudy"
                      options={studyFieldsOptions}
                      onBlur={() =>
                        formik.setFieldTouched('fieldOfStudy', true)
                      }
                      onChange={(value, actionMeta) => {
                        let v = value as TConfigItem
                        if (actionMeta.name && v) {
                          formik.setFieldValue(actionMeta.name, v.value)
                        }
                      }}
                      placeholder="Studienrichtung"
                      value={studyFieldsOptions.filter(
                        (o) => o.value === formik.values.fieldOfStudy,
                      )}
                    />
                    <Form.Control.Feedback type="invalid" className="text-end">
                      {formik.errors.fieldOfStudy}
                    </Form.Control.Feedback>
                  </Form.Group>
                </>
              )}
              <Form.Group className="mb-3" controlId="workplace">
                <Form.Label>Arbeitsstätte</Form.Label>
                <Select
                  className={
                    !!formik.errors.workplace && !!formik.touched.workplace
                      ? 'select-wrapper is-invalid'
                      : 'select-wrapper'
                  }
                  classNamePrefix="select"
                  inputId="workplace-input"
                  isDisabled={loading || isUserProfileUpdating}
                  isLoading={loading}
                  name="workplace"
                  options={workplaceOptions}
                  onBlur={() => formik.setFieldTouched('workplace', true)}
                  onChange={(value, actionMeta) => {
                    let v = value as TConfigItem
                    if (actionMeta.name && v) {
                      formik.setFieldValue(actionMeta.name, v.value)
                    }
                  }}
                  placeholder="Arbeitsstätte"
                  value={workplaceOptions.find(
                    (o) => o.value === formik.values.workplace,
                  )}
                />
                <Form.Control.Feedback type="invalid" className="text-end">
                  {formik.errors.workplace}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3" controlId="country">
                <Form.Label>Land der Arbeitsstätte</Form.Label>
                <Select
                  className={
                    !!formik.errors.country && !!formik.touched.country
                      ? 'select-wrapper is-invalid'
                      : 'select-wrapper'
                  }
                  classNamePrefix="select"
                  isDisabled={isUserProfileUpdating}
                  isLoading={loading}
                  name="country"
                  onBlur={() => formik.setFieldTouched('country', true)}
                  onChange={(value, actionMeta) => {
                    let v = value as TConfigItem
                    if (actionMeta.name && v) {
                      formik.setFieldValue(actionMeta.name, v.value)
                    }
                  }}
                  options={countryOptions}
                  placeholder="Land der Arbeitsstätte"
                  value={countryOptions.find(
                    (o) => o.value === formik.values.country,
                  )}
                />
                <Form.Control.Feedback type="invalid" className="text-end">
                  {formik.errors.country}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3" controlId="zip">
                <Form.Label>PLZ der Arbeitsstätte</Form.Label>
                <Form.Control
                  disabled={loading || isUserProfileUpdating}
                  isInvalid={!!formik.errors.zip && !!formik.touched.zip}
                  name="zip"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  placeholder="PLZ der Arbeitsstätte"
                  type="text"
                  value={formik.values.zip}
                />
                <Form.Control.Feedback type="invalid" className="text-end">
                  {formik.errors.zip}
                </Form.Control.Feedback>
              </Form.Group>
              <Button
                className="d-inline-flex align-items-center"
                variant="success"
                type="submit"
                disabled={loading || isUserProfileUpdating}
              >
                Speichern{' '}
                {isUserProfileUpdating && (
                  <div
                    className="ms-2 spinner-border spinner-border-sm"
                    role="status"
                  >
                    <span className="visually-hidden">Loading...</span>
                  </div>
                )}
              </Button>
              <Button
                className="ms-2"
                disabled={loading || isUserProfileUpdating}
                type="reset"
                variant="outline-danger"
              >
                Zurücksetzen
              </Button>
              {flashType === EAlertActionTypeKeys.Success && (
                <div className="pt-2 color-mobile-green">{flashMessage}</div>
              )}
            </Form>
          </Col>
          <Col md={6}>
            <div className="text-lg font-bold py-4">Aktionen</div>
            <div className="text-lg font-bold">Dein Profilbild</div>
            <div className="mt-2">
              <ProfilePicUploader />
            </div>
            <div className="mt-5 text-lg font-bold">
              Du möchtest dein Passwort ändern?
            </div>
            <div className="color-blue-grey-80">
              Um ein neues Passwort zu vergeben klicke den Button unterhalb und
              wir senden dir weitere Anweisungen per E-Mail zu.
            </div>
            <Button
              className="d-inline-flex align-items-center mt-2"
              variant="info"
              onClick={handleResetPassword}
              disabled={isSendingResetPasswordMutation}
            >
              Passwort ändern{' '}
              {isSendingResetPasswordMutation && (
                <div
                  className="ms-2 spinner-border spinner-border-sm"
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              )}
            </Button>
            <div className="mt-5 text-lg font-bold">
              Du möchtest deine E-Mail Adresse ändern?
            </div>
            <div className="color-blue-grey-80">
              Um eine neue E-Mail Adresse zu vergeben, gib diese bitte unterhalb
              ein und wir senden dir weitere Anweisungen per E-Mail zu.
            </div>
            <Formik
              initialValues={{
                email: '',
              }}
              onSubmit={async (values) => {
                resetEmail({ variables: { email: values.email } })
              }}
            >
              {({ errors, touched, isValidating }) => (
                <FormikForm>
                  <Field
                    id="email"
                    name="email"
                    placeholder={user?.email}
                    validate={validateEmail}
                    className="form-control mt-2"
                  />
                  {errors.email && touched.email && (
                    <Form.Control.Feedback
                      type="invalid"
                      className="text-end d-block"
                    >
                      {errors.email}
                    </Form.Control.Feedback>
                  )}
                  <Button
                    className="d-inline-flex align-items-center mt-2"
                    variant="info"
                    disabled={isValidating || isSendingResetEmailMutation}
                    type="submit"
                  >
                    E-Mail Adresse ändern{' '}
                    {isSendingResetEmailMutation && (
                      <div
                        className="ms-2 spinner-border spinner-border-sm"
                        role="status"
                      >
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    )}
                  </Button>
                </FormikForm>
              )}
            </Formik>
            <div className="mt-5">
              <DeleteUserForm />
            </div>
          </Col>
        </Row>
      </Container>
    </>
  )
}
