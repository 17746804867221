import { useNavigate, Link } from 'react-router-dom'
import { Row, Col, Button } from 'react-bootstrap'
import { TUserManagementVerificationProcessStateEnum } from '_generated/plexus.graphql'
import { ReactComponent as CheckmarkEnabledIcon } from '_components/Common/img/icon/checkmark-enabled.svg'
import { ReactComponent as CheckmarkDisabledIcon } from '_components/Common/img/icon/checkmark-disabled.svg'
import { routeConstants } from '_constants'

type TProps = {
  verificationProcessState: TUserManagementVerificationProcessStateEnum
  userEmailConfirmed: boolean
  forceUserToFinishVerification: boolean
  redirectToExternalSource?: string | null
}

export function VerificationStatusDialog(props: TProps) {
  const navigate = useNavigate()

  const returnBtn = props.redirectToExternalSource ? (
    <a
      className="btn w-100 btn-green btn-primary"
      href={props.redirectToExternalSource}
      title={`Zurück zu ${props.redirectToExternalSource}`}
    >
      Weiter
    </a>
  ) : (
    <Button
      className="btn w-100 btn-green btn-primary"
      onClick={() => {
        navigate(routeConstants.INDEX_PAGE)
      }}
    >
      Weiter
    </Button>
  )

  return (
    <div className="mb-4 mt-1 mx-sm-3">
      <Row>
        <Col xs={{ offset: 1 }}>
          <h6 className="pt-5">Ausweis Verifizierung</h6>
        </Col>
      </Row>
      {props.verificationProcessState ===
      TUserManagementVerificationProcessStateEnum.Accepted ? (
        <Row>
          <Col xs={1}>
            <CheckmarkEnabledIcon width={26} />
          </Col>
          <Col>
            Dein Ausweis wurde erfolgreich von unserem Support Team überprüft.
          </Col>
        </Row>
      ) : (
        <Row>
          <Col xs={1}>
            <CheckmarkDisabledIcon width={26} />
          </Col>
          <Col>
            Zur Sicherheit wird dein Ausweis von unserem Support Team persönlich
            überprüft.
          </Col>
        </Row>
      )}

      <Row>
        <Col xs={{ offset: 1 }}>
          <h6 className="pt-5">E-Mail Verifizierung</h6>
        </Col>
      </Row>

      {props.userEmailConfirmed ? (
        <Row>
          <Col xs={1}>
            <CheckmarkEnabledIcon width={26} />
          </Col>
          <Col>Deine E-Mail Adresse ist verifiziert.</Col>
        </Row>
      ) : (
        <>
          <Row>
            <Col xs={1}>
              <CheckmarkDisabledIcon width={26} />
            </Col>
            <Col>
              Du hast den Verifizierungslink für deine E-Mail Adresse noch nicht
              angeklickt.
            </Col>
          </Row>
          <Row>
            <Col xs={1}></Col>
            <Col>
              <Link
                className="font-small-body color-mobile-blue"
                to={routeConstants.RESEND_ACTIVATION_PAGE}
              >
                E-Mail erneut versenden
              </Link>
            </Col>
          </Row>
        </>
      )}
      <Row className="px-5 mt-2">
        <Col>
          {props.forceUserToFinishVerification ? (
            <a href="mailto:support@apoverlag.at" title="Support kontaktieren">
              Email an Support senden
            </a>
          ) : (
            returnBtn
          )}
        </Col>
      </Row>
    </div>
  )
}
