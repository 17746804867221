import { createSlice, PayloadAction, createAction } from '@reduxjs/toolkit'
import * as Sentry from '@sentry/react'
import queryString from 'query-string'
import { Telemetry } from '_services'
import { Cookie, LocalStorage } from '_helpers'
import {
  TUserManagementInvoiceData,
  TUserManagementOccupationEnum,
  TUserManagementProfile,
  TUserManagementUser,
} from '_generated/plexus.graphql'

export type TAuthenticationState = {
  fetchUserFromDb: boolean
  isAuthenticated: boolean
  isAuthenticating: boolean
  redirectTo: string | null
  tokens: {
    user: string | null
    access: string | null
    legacy: string | null
    refresh: string | null
  }
  isUserProfileUpdating: boolean
  isInvoiceDataUpdating: boolean
  user: TUserManagementUser | null
}

export type TLoginSuccessResponse = {
  expiresAt: number
  accessToken: string
  legacyToken: string
  refreshToken: string
  user: TUserManagementUser
}

export type TLoginTokens = {
  expiresAt: number
  accessToken: string
  legacyToken: string
  refreshToken: string
}

const getInitialAuthenticationState = (): TAuthenticationState => {
  let queryParams
  if (window.location.search) {
    queryParams = queryString.parse(window.location.search)
  }

  const userAsJsonString = LocalStorage.get('user')
  const accessToken = Cookie.get('plexus_access_token')
  const legacyToken = Cookie.get('jwt')
  const refreshToken = Cookie.get('plexus_refresh_token')

  const userToken = queryParams?.user_token
    ? (queryParams.user_token as string)
    : null

  const user: TUserManagementUser | null = userAsJsonString
    ? JSON.parse(userAsJsonString)
    : null

  if (user && user.uuid) {
    if (Telemetry) {
      Telemetry.setUserId(user.uuid)
    }

    if (Sentry) {
      Sentry.setUser({
        id: user.uuid,
        email: user.email ?? 'no email',
      })
    }
  }

  let redirectTo: string | null = null
  if (queryParams?.redirect) {
    if (Array.isArray(queryParams.redirect)) {
      redirectTo = queryParams.redirect[0]
    } else {
      redirectTo = queryParams.redirect
    }
  }

  return {
    fetchUserFromDb: accessToken || userToken ? true : false,
    isAuthenticated: user && accessToken ? true : false,
    isAuthenticating: false,
    redirectTo,
    tokens: {
      user: userToken,
      access: accessToken,
      legacy: legacyToken,
      refresh: refreshToken,
    },
    isUserProfileUpdating: false,
    isInvoiceDataUpdating: false,
    user: user || null,
  }
}

const initialState = getInitialAuthenticationState()

const authenticationSlice = createSlice({
  name: 'authentication',
  initialState,
  reducers: {
    fetchUserSuccess(state, action: PayloadAction<TUserManagementUser>) {
      state.fetchUserFromDb = false
      state.isAuthenticated = true
      state.isAuthenticating = false
      state.isUserProfileUpdating = false
      state.isInvoiceDataUpdating = false
      state.user = action.payload
    },
    loginFailure(state) {
      state.fetchUserFromDb = false
      state.isAuthenticated = false
      state.isAuthenticating = false
      state.redirectTo = null
      state.tokens.user = null
      state.tokens.access = null
      state.tokens.legacy = null
      state.tokens.refresh = null
      state.isUserProfileUpdating = false
      state.isInvoiceDataUpdating = false
      state.user = null
    },
    loginRequested(state) {
      state.isAuthenticating = true
    },
    loginSuccess(state, action: PayloadAction<TLoginSuccessResponse>) {
      state.isAuthenticated = true
      state.isAuthenticating = false
      state.tokens.user = null
      state.tokens.access = action.payload.accessToken
      state.tokens.legacy = action.payload.legacyToken
      state.tokens.refresh = action.payload.refreshToken
      state.user = action.payload.user
    },
    logoutRequested(state) {
      state.fetchUserFromDb = false
      state.isAuthenticated = false
      state.isAuthenticating = false
      state.redirectTo = null
      state.tokens.user = null
      state.tokens.access = null
      state.tokens.legacy = null
      state.tokens.refresh = null
      state.isUserProfileUpdating = false
      state.user = null
    },
    profileUpdateFailure(state) {
      state.isUserProfileUpdating = false
    },
    profileUpdateRequested(state) {
      state.isUserProfileUpdating = true
    },
    profileUpdateSuccess(state, action: PayloadAction<TUserManagementUser>) {
      state.isUserProfileUpdating = false
      state.user = action.payload
    },
    invoiceDataUpdateSuccess(
      state,
      action: PayloadAction<TUserManagementUser>,
    ) {
      state.isInvoiceDataUpdating = false
      state.user = action.payload
    },
    invoiceDataUpdateFailure(state) {
      state.isInvoiceDataUpdating = false
    },
    invoiceDataUpdateRequested(state) {
      state.isInvoiceDataUpdating = true
    },
    refreshTokensRequest(state, action: PayloadAction<TLoginTokens>) {
      state.tokens.access = action.payload.accessToken
      state.tokens.legacy = action.payload.legacyToken
      state.tokens.refresh = action.payload.refreshToken
    },
    registerFailure(state) {
      state.fetchUserFromDb = false
      state.isAuthenticated = false
      state.isAuthenticating = false
      state.redirectTo = null
      state.tokens.user = null
      state.tokens.access = null
      state.tokens.legacy = null
      state.tokens.refresh = null
      state.isUserProfileUpdating = false
      state.user = null
    },
    registerRequested(state) {
      state.isAuthenticating = true
    },
    registerSuccess(state, action: PayloadAction<TLoginSuccessResponse>) {
      state.isAuthenticated = true
      state.isAuthenticating = false
      state.tokens.access = action.payload.accessToken
      state.tokens.legacy = action.payload.legacyToken
      state.tokens.refresh = action.payload.refreshToken
      state.user = action.payload.user
    },
  },
})

export const fetchUserRequest = createAction(
  'authentication/fetchUserRequest',
  (accessToken: string) => ({ payload: accessToken }),
)

export const loginRequest = createAction(
  'authentication/loginRequest',
  (email: string, password: string) => ({ payload: { email, password } }),
)

export const logoutRequest = createAction('authentication/logoutRequest')

export const profileUpdateRequest = createAction(
  'authentication/profileUpdateReqeust',
  (newProfile: TUserManagementProfile, oldProfile: TUserManagementProfile) => ({
    payload: { newProfile, oldProfile },
  }),
)

export const invoiceDataUpdateRequest = createAction(
  'authentication/invoiceDataUpdateRequest',
  (newinvoiceData: TUserManagementInvoiceData) => ({
    payload: newinvoiceData,
  }),
)

export const registerRequest = createAction(
  'authentication/registerRequest',
  (
    email: string,
    password: string,
    occupation: TUserManagementOccupationEnum,
  ) => ({ payload: { email, password, occupation } }),
)

export const tokenLoginRequest = createAction(
  'authentication/tokenLoginRequest',
  (token: string) => ({ payload: token }),
)

export const {
  fetchUserSuccess,
  loginFailure,
  loginRequested,
  loginSuccess,
  logoutRequested,
  profileUpdateFailure,
  profileUpdateRequested,
  profileUpdateSuccess,
  invoiceDataUpdateRequested,
  invoiceDataUpdateSuccess,
  invoiceDataUpdateFailure,
  refreshTokensRequest,
  registerFailure,
  registerRequested,
  registerSuccess,
} = authenticationSlice.actions
export default authenticationSlice.reducer
