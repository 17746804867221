import { TAppConstants } from '_types'

export const appConstants: TAppConstants = {
  APP_VERSION: process.env.REACT_APP_APP_VERSION || '0.5.0',
  AXON_URL:
    process.env.REACT_APP_AXON_URL || 'http://localhost:8000/graphql/api',
  BASE_DOMAIN: process.env.REACT_APP_BASE_DOMAIN || 'localhost',
  BASIC_AUTH_CEREBRO: process.env.REACT_APP_BASIC_AUTH_CEREBRO || undefined,
  BASIC_AUTH_PLEXUS: process.env.REACT_APP_BASIC_AUTH_PLEXUS || undefined,
  SENTRY_DSN: process.env.REACT_APP_SENTRY_DSN || undefined,
  SENTRY_ENVIRONMENT: process.env.REACT_APP_SENTRY_ENVIRONMENT || 'development',
  SENTRY_TRACES_SAMPLE_RATE:
    process.env.REACT_APP_SENTRY_TRACES_SAMPLE_RATE || '1.0',
  IFAP_URL:
    process.env.REACT_APP_IFAP_URL ||
    'https://ifap.cerebro.dgns.wtf/graphql/api',
  MEDBASE_URL:
    process.env.REACT_APP_MEDBASE_URL ||
    'https://medbase.cerebro.dgns.wtf/graphql/api',
  CEREBRO_URL:
    process.env.REACT_APP_CEREBRO_URL || 'http://localhost:8015/dgns',
  CEREBRO_REST_URL:
    process.env.REACT_APP_CEREBRO_REST_URL || 'http://localhost:8011',
  PLEXUS_URL:
    process.env.REACT_APP_PLEXUS_URL || 'http://localhost:8040/graphql/api',
  PLEXUS_WS_URL:
    process.env.REACT_APP_PLEXUS_WS_URL || 'ws://localhost:8040/socket',
  TELEMETRY_URL:
    process.env.REACT_APP_TELEMETRY_URL || 'https://telemetry.dgns.wtf',
  MEDBASE_AUTH: process.env.REACT_APP_MEDBASE_AUTH || undefined,
  INCLUDE_GOOGLE_TAG_MANAGER:
    process.env.REACT_APP_INCLUDE_GOOGLE_TAG_MANAGER === 'true' ? true : false,
  GOOGLE_ANALYTICS_ID: process.env.REACT_APP_GOOGLE_ANALYTICS_ID || undefined,
  TEST_EMAIL: process.env.REACT_APP_TEST_EMAIL || undefined,
  TEST_PASSWORD: process.env.REACT_APP_TEST_PASSWORD || undefined,
  MOBILE_APP_LINK_IOS_AT:
    process.env.REACT_APP_MOBILE_APP_LINK_IOS_AT ||
    'https://diagnosia.page.link/beZi',
  MOBILE_APP_LINK_IOS_DE:
    process.env.REACT_APP_MOBILE_APP_LINK_IOS_DE ||
    'https://xqv4h.app.goo.gl/dqvL',
  MOBILE_APP_LINK_ANDROID_AT:
    process.env.REACT_APP_MOBILE_APP_LINK_ANDROID_AT ||
    'https://diagnosia.page.link/c6zB',
  MOBILE_APP_LINK_ANDROID_DE:
    process.env.REACT_APP_MOBILE_APP_LINK_ANDROID_DE ||
    'https://xqv4h.app.goo.gl/jm75',
}
