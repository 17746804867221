import { useEffect } from 'react'
import * as Sentry from '@sentry/react'
import { useParams, useNavigate } from 'react-router-dom'
import { routeConstants } from '_constants'
import OnboardingDialog from '_components/Common/OnboardingDialog'
import { useConfirmNewEmailMutation } from '_generated/plexus.graphql'
import { gql } from '_helpers/gql.helper'
import Loading from '_components/Common/Loading'
import { getPlexusErrorCodes } from '_helpers/plexus.helper'
import {
  AlertMessage,
  EAlertMessageType,
} from '_components/Common/AlertMessage'

function ActivationNewEmailPage() {
  const navigate = useNavigate()
  const { emailConfirmationToken } = useParams()

  const [confirmNewEmailMutation, { loading, error }] =
    useConfirmNewEmailMutation({
      client: gql.plexusClient,
    })

  useEffect(() => {
    if (emailConfirmationToken) {
      confirmNewEmailMutation({
        variables: {
          emailConfirmationToken: emailConfirmationToken,
        },
      }).catch((error) =>
        Sentry.captureException(error, {
          extra: {
            from: 'confirmNewEmailMutation',
          },
        }),
      ) // To avoid unhandled rejection
    }
  }, [emailConfirmationToken])

  const plexusErrorCodes = getPlexusErrorCodes(error)
  const showErrorDialog = plexusErrorCodes.length > 0

  return (
    <OnboardingDialog
      title="E-Mail Verifizierung"
      paragraph={
        !!error && !!loading
          ? 'Deine neue E-Mail Adresse wurde erfolgreich verifiziert.'
          : undefined
      }
    >
      {loading ? (
        <div className="p-5">
          <Loading />
        </div>
      ) : showErrorDialog ? (
        <AlertMessage
          type={EAlertMessageType.error}
          message="Dieser Verifizierungs Link ist nicht mehr gültig."
          text="Hast du diesen Link vielleicht schon einmal angeklickt? Melde dich beim Support, falls du Unterstützung benötigst."
          buttonText="Support kontaktieren"
        />
      ) : (
        <AlertMessage
          type={EAlertMessageType.success}
          message="Neue E-Mail verifiziert"
          buttonText="Weiter"
          onClick={() => {
            navigate(routeConstants.LOGIN_PAGE)
          }}
        />
      )}
    </OnboardingDialog>
  )
}

export { ActivationNewEmailPage }
