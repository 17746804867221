import { useEffect, useState } from 'react'
import { ApolloError } from '@apollo/client'
import * as Sentry from '@sentry/react'
import { ReactComponent as ErrorSvg } from './img/error.svg'
import DgnsButton from './DgnsButton'
import DeveloperErrorMsg from './DeveloperErrorMsg'

type TProps = {
  error: ApolloError
}

export default function ApolloErrorMsg({ error }: TProps) {
  const [eventId, setEventId] = useState('unknown')

  useEffect(() => {
    try {
      // Send error to Sentry
      const eventId = Sentry.captureException(error)
      setEventId(eventId)
    } catch (e) {}
  }, [error])

  function reload() {
    if (window && window.location) {
      window.location.reload()
    }
  }

  return (
    <div className="text-center">
      <ErrorSvg width="180" height="180" />
      <div className="pt-2 font-bold text-xxl" data-testid="error-title">
        Unerwarteter Fehler
      </div>
      <div>
        Es ist ein unerwarteter Fehler aufgetreten.
        <br />
        Möchtest du unseren Support darüber informieren?
      </div>
      <div className="pt-4 pb-2">
        <a href="mailto:support@apoverlag.at" title="Support kontaktieren">
          Email an Support senden
        </a>
      </div>
      <div className="pt-2">
        <span className="link" onClick={reload}>
          Seite neu laden
        </span>
      </div>
      {process.env.NODE_ENV !== 'production' && (
        <DeveloperErrorMsg error={error} />
      )}
    </div>
  )
}
