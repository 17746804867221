import type { Maybe } from 'graphql/jsutils/Maybe'
import type { Location } from 'react-router-dom'
import { useRef, useEffect } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'
import { Toaster } from 'react-hot-toast'
import * as Sentry from '@sentry/react'
import { IndexPage, IndexPageNoSearch } from '../IndexPage'
import { ProductPage } from '../ProductPage'
import { RequireAuth } from '_components/RequireAuth'
import { LoginPage } from '../LoginPage'
import { routeConstants } from '_constants'
import { SubstancePage } from '../SubstancePage'
import { AtcPage } from '../AtcPage'
import { AtcTreePage } from '../AtcTreePage'
import { SubstitutesPage } from '../SubstitutesPage'
import { InteractionsPage } from 'InteractionsPage'
import { RegistrationPage } from '../RegistrationPage'
import { PasswordRecoveryPage } from '../PasswordRecoveryPage'
import { VerificationPage } from '../VerificationPage'
import { PasswordResetPage } from '../PasswordResetPage'
import { ResendVerificationPage } from 'ResendVerificationPage'
import { IcdPage } from 'IcdPage'
import { IndicationPage } from 'IndicationPage'
import { ActivationPage } from 'ActivationPage/ActivationPage'
import { ActivationNewEmailPage } from 'ActivationNewEmailPage'
import { ProfilePage } from '../ProfilePage'
import { LogoutPage } from 'LogoutPage'
import { MedicationPage } from 'MedicationPage'
import NoMatch from '_components/Common/NoMatch'
import Header from '_components/Header/Header'
import { clearAlert } from '_slices/alert.slice'
import {
  fetchUserRequest,
  tokenLoginRequest,
} from '_slices/authentication.slice'
import { userService, bookmarkService } from '_services'
import { EPermissions } from '_types'
import AuthenticatingOverlay from '_components/Common/AuthenticatingOverlay'
import LegacyCheckInteractionsSearch from 'LegacyPages/LegacyCheckInteractionsSearch'
import LegacyCheckRenalSearch from 'LegacyPages/LegacyCheckRenalSearch'
import LegacyCheckHeparSearch from 'LegacyPages/LegacyCheckHeparSearch'
import LegacyCheckGravLactSearch from 'LegacyPages/LegacyCheckGravLactSearch'
import LegacyIndexAtcSearch from 'LegacyPages/LegacyIndexAtcSearch'
import LegacyIndexDrugSearch from 'LegacyPages/LegacyIndexDrugSearch'
import { useAppDispatch, useAppSelector } from '_hooks/store'

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes)

function paddingTopActive(uuid: Maybe<string>, location: Location): boolean {
  // No uuid means user is not logged in
  if (!uuid) {
    return false
  }

  // If user is on verification page
  if (
    location.pathname &&
    (location.pathname.startsWith(routeConstants.VERIFICATION_PAGE) ||
      location.pathname.startsWith(routeConstants.LOGIN_PAGE))
  ) {
    return false
  }

  return true
}

export default function App() {
  const prevKey = useRef<string | undefined>()
  const dispatch = useAppDispatch()
  const location = useLocation()

  const fetchUserFromDb = useAppSelector(
    (state) => state.authentication.fetchUserFromDb,
  )
  const userToken = useAppSelector((state) => state.authentication.tokens.user)
  const accessToken = useAppSelector(
    (state) => state.authentication.tokens.access,
  )
  const uuid = useAppSelector((state) => state.authentication.user?.uuid)

  useEffect(() => {
    // Only fire the clear alert event once when location changes.
    // Otherwise the event gets trigged on every rerender.
    if (prevKey.current !== location.key) {
      dispatch(clearAlert())
      prevKey.current = location.key
    }
  }, [location.key])

  useEffect(() => {
    // Only subscribe to stuff when user is logged in
    // and has access token.
    if (accessToken) {
      // TODO: implement unsubscribe
      userService.subscribe(dispatch)
      bookmarkService.subscribe()
    }
  }, [accessToken])

  // If the user closes the window/tab (without logout) or refreshes the page,
  // the user data is loaded from local storage first. We also want to fetch
  // the user from the db, so we dispatch this event here.
  useEffect(() => {
    if (fetchUserFromDb && accessToken) {
      dispatch(fetchUserRequest(accessToken))
    }
  }, [fetchUserFromDb, accessToken])

  useEffect(() => {
    if (fetchUserFromDb && userToken) {
      dispatch(tokenLoginRequest(userToken))
    }
  }, [fetchUserFromDb, userToken])

  // When an accessToken or userToken is present, but the user
  // is not yet logged in, we only show a loading page.
  if (fetchUserFromDb && !uuid) {
    return <AuthenticatingOverlay />
  }

  return (
    <>
      <Header />
      <main
        className={`vh-100 ${
          paddingTopActive(uuid, location) ? 'pt-header' : ''
        }`}
      >
        <SentryRoutes>
          <Route path={routeConstants.LOGIN_PAGE} element={<LoginPage />} />
          <Route path={routeConstants.LOGOUT_PAGE} element={<LogoutPage />} />
          <Route
            path={routeConstants.SIGNUP_PAGE}
            element={<RegistrationPage />}
          />
          <Route
            path={routeConstants.VERIFICATION_PAGE}
            element={
              <RequireAuth>
                <VerificationPage />
              </RequireAuth>
            }
          />
          <Route
            path={routeConstants.RESEND_ACTIVATION_PAGE}
            element={
              <RequireAuth>
                <ResendVerificationPage />
              </RequireAuth>
            }
          />
          <Route
            path={routeConstants.PASSWORD_RECOVERY_PAGE}
            element={<PasswordRecoveryPage />}
          />
          <Route
            path={routeConstants.ACTIVATION_PAGE}
            element={<ActivationPage />}
          />
          <Route
            path={routeConstants.ACTIVATION_NEW_EMAIL_PAGE}
            element={<ActivationNewEmailPage />}
          />
          <Route
            path={routeConstants.PASSWORD_RESET_PAGE}
            element={<PasswordResetPage />}
          />
          <Route
            path={routeConstants.ROOT_PAGE}
            element={
              <RequireAuth>
                <IndexPageNoSearch />
              </RequireAuth>
            }
          />
          <Route
            path={routeConstants.INDEX_PAGE}
            element={
              <RequireAuth>
                <IndexPageNoSearch />
              </RequireAuth>
            }
          />
          {/*<Route
            path={routeConstants.MEDICATION_PAGE}
            element={
              <RequireAuth>
                <MedicationPage />
              </RequireAuth>
            }
          />
          <Route
            path={routeConstants.ATC_TREE}
            element={
              <RequireAuth>
                <AtcTreePage />
              </RequireAuth>
            }
          />
          <Route
            path={routeConstants.INTERACTIONS_PAGE}
            element={
              <RequireAuth permissions={[EPermissions.CanUseInteractions]}>
                <InteractionsPage />
              </RequireAuth>
            }
          />
          <Route
            path="/product/:id/substitutes/:atcCode"
            element={
              <RequireAuth>
                <SubstitutesPage />
              </RequireAuth>
            }
          />
          <Route
            path="/product/:id"
            element={
              <RequireAuth>
                <ProductPage />
              </RequireAuth>
            }
          />
          <Route
            path="/substance/:id"
            element={
              <RequireAuth>
                <SubstancePage />
              </RequireAuth>
            }
          />
          <Route
            path="/atc/:code"
            element={
              <RequireAuth>
                <AtcPage />
              </RequireAuth>
            }
          />
          <Route
            path="/icd/:code"
            element={
              <RequireAuth>
                <IcdPage />
              </RequireAuth>
            }
          />
          <Route
            path="/indication/:code"
            element={
              <RequireAuth>
                <IndicationPage />
              </RequireAuth>
            }
          />*/}
          <Route
            path={routeConstants.PROFILE_PAGE}
            element={
              <RequireAuth>
                <ProfilePage />
              </RequireAuth>
            }
          />
          {/*<Route
            path={routeConstants.ANNOUNCEMENTS_PAGE}
            element={
              <RequireAuth
                permissions={[
                  EPermissions.CanViewAnnouncements,
                  EPermissions.CanViewPharmacovigilence,
                ]}
              >
                <AnnouncementsPage />
              </RequireAuth>
            }
          />
          <Route
            path={routeConstants.ANNOUNCEMENTS_CREATE}
            element={
              <RequireAuth permissions={[EPermissions.CanManageAnnouncements]}>
                <AnnouncementCreate />
              </RequireAuth>
            }
          />
          <Route
            path={`${routeConstants.ANNOUNCEMENTS_EDIT}/:id`}
            element={
              <RequireAuth permissions={[EPermissions.CanManageAnnouncements]}>
                <AnnouncementEdit />
              </RequireAuth>
            }
          />
          <Route
            path={`${routeConstants.ANNOUNCEMENTS_SHOW}/:id`}
            element={
              <RequireAuth permissions={[EPermissions.CanViewAnnouncements]}>
                <AnnouncementShow />
              </RequireAuth>
            }
          />
          <Route
            path={routeConstants.LEGACY_CHECK_INTERACTIONS_SEARCH}
            element={
              <RequireAuth>
                <LegacyCheckInteractionsSearch />
              </RequireAuth>
            }
          />
          <Route
            path={routeConstants.LEGACY_CHECK_GRAVLACT_SEARCH}
            element={
              <RequireAuth>
                <LegacyCheckGravLactSearch />
              </RequireAuth>
            }
          />
          <Route
            path={routeConstants.LEGACY_CHECK_HEPAR_SEARCH}
            element={
              <RequireAuth>
                <LegacyCheckHeparSearch />
              </RequireAuth>
            }
          />
          <Route
            path={routeConstants.LEGACY_CHECK_RENAL_SEARCH}
            element={
              <RequireAuth>
                <LegacyCheckRenalSearch />
              </RequireAuth>
            }
          />
          <Route
            path={routeConstants.LEGACY_INDEX_ATC_SEARCH}
            element={
              <RequireAuth>
                <LegacyIndexAtcSearch />
              </RequireAuth>
            }
          />
          <Route
            path={routeConstants.LEGACY_INDEX_DRUG_SEARCH}
            element={
              <RequireAuth>
                <LegacyIndexDrugSearch />
              </RequireAuth>
            }
          />
          <Route
            path={routeConstants.LEGACY_INDEX_DRUG_SHOW}
            element={
              <RequireAuth>
                <LegacyIndexDrugSearch />
              </RequireAuth>
            }
          />
          <Route
            path={routeConstants.PHARMA_NOTIFICATION_CREATE}
            element={
              <RequireAuth
                permissions={[EPermissions.CanManagePharmacovigilence]}
              >
                <PharmaNotificationCreate />
              </RequireAuth>
            }
          />
          <Route
            path={`${routeConstants.PHARMA_NOTIFICATION_EDIT}/:id`}
            element={
              <RequireAuth
                permissions={[EPermissions.CanManagePharmacovigilence]}
              >
                <PharmaNotificationEdit />
              </RequireAuth>
            }
          />*/}
          <Route path="*" element={<NoMatch />} />
        </SentryRoutes>
      </main>
      <Toaster position="top-right" toastOptions={{ duration: 5000 }} />
    </>
  )
}
