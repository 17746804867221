import { useEffect } from 'react'
import { Row, Col, Container, Alert } from 'react-bootstrap'
import { utils } from '_helpers'
import News from '_components/News/News'
import LearnContainer from '_components/Learn-Index/LearnContainer'
import Footer from '_components/Footer/Footer'
import { useAppSelector } from '_hooks/store'
import { TUserManagementCountryEnum } from '_generated/plexus.graphql'
import { ReactComponent as AppleIcon } from '_components/Header/img/apple-badge.svg'
import { ReactComponent as GoogleIcon } from '_components/Header/img/google-badge.svg'

export function IndexPageNoSearch() {
  const news = useAppSelector((state) => state.authentication.user?.news)
  const mobileAppLinkAndroid = utils.getMobileAppLink(
    TUserManagementCountryEnum.At,
    'android',
  )
  const mobileAppLinkIOS = utils.getMobileAppLink(
    TUserManagementCountryEnum.At,
    'ios',
  )

  useEffect(() => {
    document.title = 'Diagnosia Web App'
  }, [])

  return (
    <>
      <Container fluid="xl">
        <Row>
          <Col md={{ span: 8, offset: 2 }} className="pt-4">
            <Alert
              variant="light"
              className="border-blue-grey-30 bg-blue-grey-10"
            >
              <p className="mt-3 mb-0">
                Hol dir hier die <strong>Diagnosia Mobile App</strong>:<br />
              </p>
              <div className="d-flex align-items-center py-4">
                <a
                  className="d-inline-block"
                  href={mobileAppLinkIOS}
                  rel="noopener noreferrer"
                  target="_blank"
                  title="Download Diagnosia App für iOS"
                >
                  <AppleIcon height="40" width="120" viewBox="0 0 120 40" />
                </a>
                <a
                  className="d-inline-block ms-2"
                  href={mobileAppLinkAndroid}
                  rel="noopener noreferrer"
                  target="_blank"
                  title="Download Diagnosia App für Android"
                >
                  <GoogleIcon height="50" width="130" />
                </a>
              </div>
              <p className="mb-0">
                Du willst lieber auf deinem <strong>PC</strong> oder{' '}
                <strong>Laptop</strong> arbeiten und ein{' '}
                <strong>umfassendes Recherchetool</strong> verwenden? Dann ist
                der <strong>Austria-Codex Online</strong> genau das Richtige für
                dich!
                <br />
                <br />
                Teste jetzt die <strong>kostenlose</strong> und{' '}
                <strong>unverbindliche 14-tägige Testversion</strong> des{' '}
                <strong>Austria-Codex Online</strong> unter{' '}
                <a
                  className="link"
                  href="https://mein.apoverlag.at/austriacodex"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  mein.apoverlag.at/austriacodex
                </a>
                .
              </p>
            </Alert>
          </Col>
        </Row>
      </Container>
      <div className="bg-grey-lightest mt-4 pb-5 bt-4-light ">
        <Container fluid="xl">
          {news && news.length > 0 ? (
            <Row>
              <Col className="pt-4">
                <News newsItems={news} />
              </Col>
            </Row>
          ) : null}
          <LearnContainer />
          <Footer />
        </Container>
      </div>
    </>
  )
}
